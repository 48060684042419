<template>
  <v-container>
    <v-row>
      <v-col>
        <LocationForm :location="location" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import LocationForm from "@/components/LocationForm"
export default {
	name: 'EditLocation',
	components: {
		LocationForm
	},
	data: () => ({
	}),
	methods: {
		showCreateCategoryDialog() {

		}
	},
	computed: {
		location() {
			return this.$store.state.locations.find(location => { return location.uuid === this.$route.params.uuid })
		},
	},
	beforeMount() {

	},
	mounted() {

	}
}
</script>
